var render = function render(){var _vm=this,_c=_vm._self._c;return _c('tr',{class:[
    {
      'weekly-background': _vm.colorWeeklyDose,
      'squeeze-table': _vm.squeezeTable,
    },
  ]},[_c('td',{staticClass:"pl-6",class:{ 'is-deleted': _vm.item.isDeleted }},[_c('InsulinDiaryDoseType',{attrs:{"doseTypeShort":_vm.item.doseTypeShort,"isFollowUp":_vm.item.isFollowUp,"landscape":_vm.landscape,"isDeleted":_vm.item.isDeleted}})],1),_c('td',{class:{ 'is-deleted': _vm.item.isDeleted }},[_c('DateFormat',{attrs:{"value":_vm.item.date,"data-testid":"insulin-dose-date"}})],1),(_vm.doseTaken)?[_c('td',{staticClass:"text-right",class:{ 'is-deleted': _vm.item.isDeleted }},[_c('InsulinDiaryDoseTime',{attrs:{"doseTime":_vm.item.time,"hourFormatString":_vm.hourFormatString,"landscape":_vm.landscape,"isDeleted":_vm.item.isDeleted}})],1),_c('td',{staticClass:"text-right",class:{ 'is-deleted': _vm.item.isDeleted }},[_c('InsulinDiaryDoseValue',{attrs:{"dose":_vm.item,"landscape":_vm.landscape,"isDeleted":_vm.item.isDeleted}})],1),(!_vm.hideCarbsConsumed)?_c('td',{staticClass:"text-right",class:{ 'is-deleted': _vm.item.isDeleted }},[_c('InsulinDiaryCarbsConsumed',{attrs:{"carbsConsumed":_vm.item.carbsConsumed,"carbsConsumedType":_vm.item.carbsConsumedType,"landscape":_vm.landscape,"isDeleted":_vm.item.isDeleted}})],1):_vm._e()]:_c('td',{class:{ 'is-deleted': _vm.item.isDeleted },attrs:{"colspan":_vm.hideCarbsConsumed ? 2 : 3}},[_c('v-chip',{staticClass:"width-100-pct align-center justify-center",attrs:{"label":"","small":"","color":_vm.labelColor,"text-color":"#333","data-testid":"bmg-chip-msg"}},[_c('span',{class:{
          'deleted-dose-color': _vm.item.isDeleted,
        }},[_vm._v(" "+_vm._s(_vm.doseLabel)+" ")])])],1),(!_vm.hidePrescribedDose)?_c('td',{staticClass:"text-right"},[_c('InsulinDiaryPrescribedDose',{attrs:{"dose":_vm.item}})],1):_vm._e(),(!_vm.hideAudit)?_c('td',{staticClass:"text-right pr-6"},[(_vm.item.versionCount)?_c('InsulinAuditDialog',{attrs:{"patient-no":_vm.patientNo,"item-id":_vm.item.insulinId,"version-count":_vm.item.versionCount,"landscape":_vm.landscape,"hideCarbsConsumed":_vm.item.carbsConsumedType === 'na'}}):_vm._e()],1):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }