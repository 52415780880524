<template functional>
  <svg
    width="161"
    height="160"
    viewBox="0 0 161 160"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="1" width="160" height="160" rx="80" fill="#FEF8FA" />
    <path
      d="M67.5996 123.411C61.5752 124.745 56.375 120.96 55.7915 114.819L99.2897 55.0343L111.098 63.626L67.5996 123.411Z"
      fill="#FEF8FA"
      stroke="#001965"
      stroke-width="1.4"
      stroke-miterlimit="10"
    />
    <path
      d="M103.942 51.2199L100.872 55.4428"
      stroke="#001965"
      stroke-width="1.4"
      stroke-miterlimit="10"
    />
    <path
      d="M110.28 55.7856L107.202 60.0085"
      stroke="#001965"
      stroke-width="1.4"
      stroke-miterlimit="10"
    />
    <path
      d="M107.1 53.5174L104.03 57.7403"
      stroke="#001965"
      stroke-width="1.4"
      stroke-miterlimit="10"
    />
    <path
      d="M113.43 58.0903L110.36 62.3133"
      stroke="#001965"
      stroke-width="1.4"
      stroke-miterlimit="10"
    />
    <path
      d="M102.638 49.9071L115 58.8999"
      stroke="#001965"
      stroke-width="1.4"
      stroke-miterlimit="10"
    />
    <path
      d="M96.2259 72.6773L95.8612 72.4147C94.9423 71.7437 94.738 70.4527 95.409 69.5338L98.2098 65.6828C98.8808 64.7638 100.172 64.5596 101.091 65.2306L101.455 65.4932C102.374 66.1642 102.579 67.4551 101.908 68.3741L99.1068 72.2251C98.4358 73.144 97.1449 73.341 96.2259 72.6773Z"
      fill="#FEF8FA"
      stroke="#001965"
      stroke-width="1.4"
      stroke-miterlimit="10"
    />
    <path
      d="M80.5825 80.6563L92.201 89.1167"
      stroke="#001965"
      stroke-width="1.4"
      stroke-miterlimit="10"
    />
    <path
      d="M66.0312 117.598L62.0781 114.724L76.4244 95.0098C77.1391 94.0252 78.5176 93.8063 79.5022 94.5211L79.8961 94.8056C80.8807 95.5203 81.0995 96.8988 80.3847 97.8834L66.0312 117.598Z"
      fill="#FEF8FA"
      stroke="#001965"
      stroke-width="1.4"
      stroke-miterlimit="10"
    />
    <path
      d="M55.2834 45.9294H79.9756M71.2607 55.2881V72.5655L63.9983 68.2462V55.2881H71.2607ZM82.8806 43.0499L71.2607 55.2881H63.9983L52.3784 43.0499V40.8902H82.8806V43.0499Z"
      stroke="#001965"
      stroke-width="1.4"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: 'ImgInsulinFilter',
}
</script>
