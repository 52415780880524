<template functional>
  <svg
    width="160"
    height="160"
    viewBox="0 0 160 160"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="160" height="160" rx="80" fill="#F5F6F9" />
    <mask
      id="mask0"
      mask-type="alpha"
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="160"
      height="160"
    >
      <rect width="160" height="160" rx="80" fill="#D9E6F8" />
    </mask>
    <g mask="url(#mask0)">
      <path
        d="M87.7829 73.2007C90.6369 73.2007 92.9505 70.9191 92.9505 68.1046C92.9505 65.2901 90.6369 63.0085 87.7829 63.0085C84.9289 63.0085 82.6153 65.2901 82.6153 68.1046C82.6153 70.9191 84.9289 73.2007 87.7829 73.2007Z"
        stroke="#001965"
        stroke-miterlimit="10"
      />
      <path
        d="M84.5656 64.1216L91.0353 72.0671"
        stroke="#001965"
        stroke-miterlimit="10"
      />
      <path
        d="M102.402 63.5804C103.679 62.0025 103.418 59.7047 101.82 58.4434L95.0695 53.1226C93.4712 51.8614 91.1376 52.1167 89.861 53.6945C88.5845 55.2724 88.8449 57.5702 90.4432 58.8315L97.1938 64.1523C98.7921 65.4136 101.126 65.1583 102.402 63.5804Z"
        stroke="#001965"
        stroke-miterlimit="10"
      />
      <path
        d="M98.4501 55.783L93.8187 61.4919"
        stroke="#001965"
        stroke-miterlimit="10"
      />
      <path
        d="M68.7053 70.8416C64.4874 71.776 60.8466 69.1259 60.4381 64.8263L90.8923 22.9697L99.1595 28.9849L68.7053 70.8416Z"
        stroke="#001965"
        stroke-miterlimit="10"
      />
      <path
        d="M94.1502 20.2991L92.0004 23.2556"
        stroke="#001965"
        stroke-miterlimit="10"
      />
      <path
        d="M98.5875 23.4956L96.4326 26.4522"
        stroke="#001965"
        stroke-miterlimit="10"
      />
      <path
        d="M96.3611 21.9076L94.2114 24.8642"
        stroke="#001965"
        stroke-miterlimit="10"
      />
      <path
        d="M100.793 25.1093L98.6436 28.0659"
        stroke="#001965"
        stroke-miterlimit="10"
      />
      <path
        d="M93.2361 19.3799L101.891 25.6761"
        stroke="#001965"
        stroke-miterlimit="10"
      />
      <path
        d="M88.7476 35.3219L88.4923 35.1381C87.8489 34.6683 87.7059 33.7645 88.1757 33.1211L90.1365 30.4249C90.6063 29.7815 91.5101 29.6386 92.1535 30.1084L92.4089 30.2922C93.0522 30.762 93.1952 31.6658 92.7254 32.3092L90.7646 35.0053C90.2948 35.6487 89.391 35.7866 88.7476 35.3219Z"
        stroke="#001965"
        stroke-miterlimit="10"
      />
      <path
        d="M77.795 40.9083L85.9294 46.8316"
        stroke="#001965"
        stroke-miterlimit="10"
      />
      <path
        d="M67.6075 66.7718L64.8398 64.7599L74.884 50.9575C75.3844 50.2681 76.3495 50.1149 77.0389 50.6153L77.3146 50.8145C78.004 51.3149 78.1572 52.28 77.6567 52.9694L67.6075 66.7718Z"
        stroke="#001965"
        stroke-miterlimit="10"
      />
      <path
        d="M102.989 111.406L115.975 111.498C118.191 111.534 120.019 109.828 120.05 107.684C120.085 105.56 118.344 103.803 116.148 103.742L82.4261 101.745C78.9334 101.699 75.4406 102.108 72.096 103.119C65.9275 104.988 60.6272 108.286 59.0901 114.046"
        stroke="#001965"
        stroke-miterlimit="10"
      />
      <path
        d="M102.06 126.348L123.114 126.685C125.172 126.715 126.811 128.416 126.775 130.484C126.739 132.547 125.049 134.196 122.991 134.16L105.068 133.941"
        stroke="#001965"
        stroke-miterlimit="10"
      />
      <path
        d="M102.065 126.261L123.118 126.598C125.176 126.628 126.866 124.989 126.902 122.937C126.933 120.879 125.294 119.188 123.241 119.153L102.187 118.816"
        stroke="#001965"
        stroke-miterlimit="10"
      />
      <path
        d="M102.162 118.841L123.216 119.178C125.274 119.209 126.964 117.57 127 115.517C127.03 113.459 125.391 111.769 123.338 111.733L102.285 111.396"
        stroke="#001965"
        stroke-miterlimit="10"
      />
      <path
        d="M-4.19775 84.6797C15.7782 118.984 52.5949 139.986 92.2865 140.747L107.028 141.028L107.82 140.992C109.898 140.9 111.522 139.272 111.435 137.352C111.354 135.432 109.597 133.946 107.519 134.032L101.248 133.777C99.8745 133.721 98.552 133.328 97.3979 132.649C95.5341 131.551 93.558 130.152 91.8473 128.461C83.1053 119.816 76.4364 118.851 70.9982 120.93"
        stroke="#001965"
        stroke-miterlimit="10"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'ImgInsulinDataMissing',
}
</script>
