var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-data-table',{class:{ 'no-data-table': !_vm.hasData },attrs:{"data-testid":"insulin-diary-data-table","headers":_vm.getTableHeaders(),"hide-default-header":!_vm.hasData || this.filteredItems.length === 0,"items":_vm.filteredItems,"page":_vm.page,"hide-default-footer":!_vm.hasPagination,"footer-props":_vm.hasPagination
      ? {
          'items-per-page-options': _vm.itemsPerPageOptions,
        }
      : { 'items-per-page-options': [11000] }},on:{"update:page":function($event){_vm.page=$event}},scopedSlots:_vm._u([{key:"top",fn:function({ pagination, options, updateOptions }){return [(_vm.items.length !== 0)?_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"pl-4 py-2 col-md-7 col-lg-6 order-md-first align-center",class:[
          {
            'col-sm-12 col-lg-12 order-lg-last pr-6': _vm.hasMultipleDoseTypes,
          },
          { 'col-sm-3': !_vm.hasMultipleDoseTypes },
        ]},[_c('div',{staticClass:"d-flex justify-space-between pb-3"},[(_vm.hasMultipleDoseTypes)?_c('v-select',{staticClass:"ml-2 mt-0 dose-type-filter",attrs:{"items":_vm.doseTypes,"placeholder":"Filter by dose type","dense":"","outlined":"","multiple":"","clearable":"","hide-details":""},scopedSlots:_vm._u([{key:"selection",fn:function({ item }){return [_c('v-chip',{staticClass:"narrow",attrs:{"label":"","small":""}},[_c('span',{staticClass:"hidden-sm-and-down"},[_vm._v(_vm._s(item))]),_c('span',{staticClass:"hidden-md-and-up"},[_vm._v(_vm._s(item)+" dose")])])]}}],null,true),model:{value:(_vm.doseTypeFilter),callback:function ($$v) {_vm.doseTypeFilter=$$v},expression:"doseTypeFilter"}}):_vm._e(),(!_vm.maxDays)?_c('v-select',{staticClass:"ml-2 days-filter shrink",attrs:{"items":_vm.daysFilterOptions,"outlined":"","dense":"","hide-details":""},scopedSlots:_vm._u([{key:"selection",fn:function({ item }){return [_c('span',{staticClass:"font-x-small"},[_vm._v(" "+_vm._s(item.textShort)+" ")])]}}],null,true),model:{value:(_vm.daysFilter),callback:function ($$v) {_vm.daysFilter=$$v},expression:"daysFilter"}}):_vm._e()],1)]),(_vm.hasPagination)?_c('v-col',{staticClass:"col-md-5",class:[
          {
            'col-sm-12 order-sm-first col-lg-12 order-lg-first':
              _vm.hasMultipleDoseTypes,
          },
          { 'col-sm-9': !_vm.hasMultipleDoseTypes },
        ]},[_c('v-data-footer',{staticClass:"top-footer-item squeze-margin",attrs:{"pagination":pagination,"options":options,"items-per-page-text":"$vuetify.dataTable.itemsPerPageText","items-per-page-options":_vm.itemsPerPageOptions},on:{"update:options":updateOptions}})],1):_vm._e()],1):_vm._e()]}},{key:"item",fn:function({ item }){return [_c('InsulinDoseRow',{attrs:{"item":item,"hourFormatString":_vm.hourFormatString,"patientNo":_vm.patientNo,"hasMultipleDoseTypes":_vm.hasMultipleDoseTypes,"hideAudit":_vm.hideAudit,"hideCarbsConsumed":_vm.hideCarbsConsumed,"hidePrescribedDose":_vm.hidePrescribedDose,"landscape":_vm.landscape}})]}},{key:"no-data",fn:function(){return [(_vm.isLoading)?[_vm._v("Loading... Please wait ")]:[(_vm.loadingState === _vm.loadingState.LOAD_ERRORED)?_c('UnableToLoadData'):(_vm.hasActiveFilter)?_c('div',{staticClass:"py-16 mb-13"},[_c('div',{staticClass:"d-flex align-center justify-center mx-auto my-4"},[_c('ImgInsulinFilter')],1),_c('strong',{staticClass:"no-data-text d-block mb-4"},[_vm._v("There are no doses"),_c('br'),_vm._v("to display for the time period selected.")]),(!_vm.maxDays)?_c('v-btn',{staticClass:"clear-link",attrs:{"rounded":"","outlined":""},on:{"click":_vm.clearFilters}},[_vm._v("Show all dates")]):_vm._e()],1):_c('div',{staticClass:"py-16 my-16"},[_c('div',{staticClass:"d-flex align-center justify-center mx-auto my-4"},[_c('ImgInsulinDataMissing')],1),_c('strong',{staticClass:"no-data-text d-block mb-4"},[_vm._v("There are currently no doses"),_c('br'),_vm._v("to display for this patient.")])])]]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }