<template>
  <v-data-table
    data-testid="insulin-diary-data-table"
    :headers="getTableHeaders()"
    :hide-default-header="!hasData || this.filteredItems.length === 0"
    :items="filteredItems"
    :page.sync="page"
    :class="{ 'no-data-table': !hasData }"
    :hide-default-footer="!hasPagination"
    :footer-props="
      hasPagination
        ? {
            'items-per-page-options': itemsPerPageOptions,
          }
        : { 'items-per-page-options': [11000] }
    "
  >
    <template #top="{ pagination, options, updateOptions }">
      <v-row v-if="items.length !== 0" no-gutters>
        <v-col
          :class="[
            {
              'col-sm-12 col-lg-12 order-lg-last pr-6': hasMultipleDoseTypes,
            },
            { 'col-sm-3': !hasMultipleDoseTypes },
          ]"
          class="pl-4 py-2 col-md-7 col-lg-6 order-md-first align-center"
        >
          <div class="d-flex justify-space-between pb-3">
            <v-select
              v-if="hasMultipleDoseTypes"
              v-model="doseTypeFilter"
              :items="doseTypes"
              placeholder="Filter by dose type"
              dense
              outlined
              multiple
              clearable
              hide-details
              class="ml-2 mt-0 dose-type-filter"
            >
              <template #selection="{ item }">
                <v-chip label small class="narrow">
                  <span class="hidden-sm-and-down">{{ item }}</span>
                  <span class="hidden-md-and-up">{{ item }} dose</span>
                </v-chip>
              </template>
            </v-select>
            <v-select
              v-if="!maxDays"
              v-model="daysFilter"
              :items="daysFilterOptions"
              outlined
              dense
              hide-details
              class="ml-2 days-filter shrink"
            >
              <template #selection="{ item }">
                <span class="font-x-small">
                  {{ item.textShort }}
                </span>
              </template>
            </v-select>
          </div>
        </v-col>
        <v-col
          v-if="hasPagination"
          :class="[
            {
              'col-sm-12 order-sm-first col-lg-12 order-lg-first':
                hasMultipleDoseTypes,
            },
            { 'col-sm-9': !hasMultipleDoseTypes },
          ]"
          class="col-md-5"
        >
          <v-data-footer
            :pagination="pagination"
            :options="options"
            @update:options="updateOptions"
            items-per-page-text="$vuetify.dataTable.itemsPerPageText"
            :items-per-page-options="itemsPerPageOptions"
            class="top-footer-item squeze-margin"
        /></v-col>
      </v-row>
    </template>

    <template #item="{ item }">
      <InsulinDoseRow
        :item="item"
        :hourFormatString="hourFormatString"
        :patientNo="patientNo"
        :hasMultipleDoseTypes="hasMultipleDoseTypes"
        :hideAudit="hideAudit"
        :hideCarbsConsumed="hideCarbsConsumed"
        :hidePrescribedDose="hidePrescribedDose"
        :landscape="landscape"
      />
    </template>

    <template #no-data>
      <template v-if="isLoading">Loading... Please wait </template>
      <template v-else>
        <UnableToLoadData v-if="loadingState === loadingState.LOAD_ERRORED" />
        <div v-else-if="hasActiveFilter" class="py-16 mb-13">
          <div class="d-flex align-center justify-center mx-auto my-4">
            <ImgInsulinFilter />
          </div>

          <strong class="no-data-text d-block mb-4"
            >There are no doses<br />to display for the time period
            selected.</strong
          >

          <v-btn
            v-if="!maxDays"
            @click="clearFilters"
            rounded
            outlined
            class="clear-link"
            >Show all dates</v-btn
          >
        </div>
        <div v-else class="py-16 my-16">
          <div class="d-flex align-center justify-center mx-auto my-4">
            <ImgInsulinDataMissing />
          </div>

          <strong class="no-data-text d-block mb-4"
            >There are currently no doses<br />to display for this
            patient.</strong
          >
        </div>
      </template>
    </template>
  </v-data-table>
</template>

<script>
import Vue from 'vue'

import ImgInsulinDataMissing from '@/components/images/ImgInsulinDataMissing.vue'
import ImgInsulinFilter from '@/components/images/ImgInsulinFilter.vue'
import InsulinDoseRow from './_InsulinDoseRow.vue'

import hourFormatter from '@/utils/date/hourFormatter'
import { selectedPatientMapGetters } from '@/store/modules/selectedPatientModule'
import service from '@/services/insulin-service'
import loadingState from '@/constants/loadingState'
import itemsPerPageOptions from '@/constants/itemsPerPageOptionsPagination'
import globalProgressActionsMixins from '@/components/mixins/store/globalProgressActionsMixins'
import period from '../blood-glucose-measurements/self-monitoring/period'

export default Vue.extend({
  name: 'InsulinList',
  mixins: [globalProgressActionsMixins],
  components: {
    ImgInsulinDataMissing,
    ImgInsulinFilter,
    InsulinDoseRow,
    UnableToLoadData: () => import('@/components/UnableToLoadData.vue'),
  },
  props: {
    patientNo: { type: String, required: true },
    maxDays: { type: Number, required: false, default: null },
    hideAudit: { type: Boolean, required: false, default: false },
  },
  data() {
    return {
      page: 1,
      itemsPerPageOptions,
      loadingState: loadingState.INITIAL,
      daysFilter: period.FOURTEEN_DAYS,
      daysFilterOptions: [
        { text: 'Last 7 days', textShort: '7 days', value: period.WEEK },
        {
          text: 'Last 14 days',
          textShort: '14 days',
          value: period.FOURTEEN_DAYS,
        },
        {
          text: 'Last 28 days',
          textShort: '28 days',
          value: period.TWENTY_EIGHT_DAYS,
        },
        { text: 'All data', textShort: 'All data', value: period.ALL_DATA },
      ],
      items: [],
      doseTypeFilter: [],
      doseTypes: [],
    }
  },
  computed: {
    ...selectedPatientMapGetters(),
    hasPagination() {
      return this.daysFilter === period.ALL_DATA
    },
    hasActiveFilter() {
      return this.items.length !== this.filteredItems.length
    },
    hasMultipleDoseTypes() {
      return this.doseTypes?.length > 1
    },
    filteredItems() {
      if (this.items.length === 0) return []
      let result = this.items.filter(x => x.dayOffset <= this.getDaysFilter)
      if (this.doseTypeFilter.length > 0) {
        result = result.filter(x =>
          this.doseTypeFilter.includes(x.doseTypeShort)
        )
      }
      return result
    },

    getDaysFilter() {
      return this.maxDays > this.daysFilter ? this.maxDays : this.daysFilter
    },

    isLoading() {
      return this.loadingState === loadingState.LOADING
    },
    clockNotation() {
      return this.selectedPatientClockNotation(this.patientNo)
    },
    clockNotationDisplay() {
      return `(${this.clockNotation})`
    },
    hourFormatString() {
      return hourFormatter(this.clockNotation)
    },
    hasData() {
      return this.items.length > 0
    },
    hideCarbsConsumed() {
      // if all filtered insulin has carbsConsumedType === 'not_applicable'
      return (
        this.filteredItems.filter(x => x.carbsConsumedType !== 'na').length ===
        0
      )
    },
    hidePrescribedDose() {
      // if all filtered insulin has insulinType === 'bolus' and prescribedDose undefined
      return (
        this.filteredItems.filter(
          x => !x.insulinType === 'bolus' || x.prescribedDose
        ).length === 0
      )
    },
    landscape() {
      return !this.$vuetify.breakpoint.smAndDown
    },
    squeezeTable() {
      return (
        !this.landscape && !this.hidePrescribedDose && !this.hideCarbsConsumed
      )
    },
  },
  methods: {
    getUniqueDoseTypes() {
      if (this.items.length > 0) {
        const listOfTypes = this.items.map(dose => dose.doseTypeShort)
        const uniqueTypes = [...new Set(listOfTypes)]

        return uniqueTypes
      }
      return []
    },
    assignDoseTypeInfo(dose) {
      // timepoint will be set to not_applicable for all non-bolus medication where isFollowup is true,
      // and in follow-up period patients only take daily_dose
      if (dose.isFollowUp && dose.timepoint === 'not_applicable') {
        return {
          ...dose,
          doseTypeShort: 'Follow up daily',
        }
      }
      // if a timepoint is specified, that should be translated into user friendly text appended after a follow up marker
      if (dose.isFollowUp) {
        return {
          ...dose,
          doseTypeShort:
            'Follow up ' +
            this.$t(`time-point-short.${dose.timepoint}`).toLowerCase(),
        }
      }

      return {
        ...dose,
        doseTypeShort: this.$t(`time-point-short.${dose.timepoint}`),
      }
    },
    refreshData() {
      this.fetchInsulinList()
    },
    clearFilters() {
      if (this.doseTypeFilter.length === 0) {
        this.daysFilter = period.ALL_DATA
      }
      this.doseTypeFilter = []
    },
    fetchInsulinList() {
      this.items = []
      this.loadingState = loadingState.LOADING
      this.globalStartLoadingRequested()

      service
        .getInsulinList(this.patientNo, this.maxDays)
        .then(response => {
          this.items = response.items.map(dose => this.assignDoseTypeInfo(dose))
          this.doseTypes = this.getUniqueDoseTypes()
          this.loadingState = loadingState.LOAD_SUCCEEDED
        })
        .catch(error => {
          this.loadingState = loadingState.LOAD_ERRORED
          this.$log.error(error)
          Vue.$tracking.componentError(this, error)
        })
        .finally(() => {
          this.globalStopLoadingRequested()
        })
    },
    getTableHeaders() {
      let list = [
        {
          text: 'Dose type',
          value: 'doseTypeShort',
          sortable: false,
          width: this.landscape
            ? this.hideCarbsConsumed
              ? '200px'
              : '160px'
            : this.squeezeTable
            ? '110px'
            : '140px',
          class: 'pl-6',
        },
        {
          text: 'Date',
          value: 'date',
          sortable: true,
          width: this.landscape
            ? '130px'
            : this.squeezeTable
            ? '110px'
            : '120px',
        },
        {
          text: `Time ${this.clockNotationDisplay}`,
          value: 'time',
          sortable: false,
          align: 'end',
          width: this.landscape
            ? '150px'
            : this.squeezeTable
            ? '100px'
            : '110px',
        },
        {
          text: 'Dose taken',
          value: 'actual',
          sortable: false,
          align: 'end',
          width: this.landscape
            ? '150px'
            : this.squeezeTable
            ? '100px'
            : '110px',
        },
        {
          text: 'Carbs consumed',
          value: 'carbs',
          sortable: false,
          align: 'end',
          width: this.landscape
            ? '150px'
            : this.squeezeTable
            ? '100px'
            : '110px',
        },
        {
          text: 'Prescribed dose',
          value: 'prescribed',
          sortable: false,
          align: 'end',
        },
        { text: '', value: 'audit', sortable: false },
      ]

      if (this.hideAudit) {
        list = list.filter(item => item.value !== 'audit')
      }
      if (this.hideCarbsConsumed) {
        list = list.filter(item => item.value !== 'carbs')
      }
      if (this.hidePrescribedDose) {
        list = list.filter(item => item.value !== 'prescribed')
      }

      return list
    },
  },
  created() {
    this.fetchInsulinList()
  },
  watch: {
    daysFilter() {
      this.page = 1
    },
    doseTypeFilter() {
      this.page = 1
    },
  },
})
</script>

<style lang="scss" scoped>
.dose-type-filter {
  width: 250px;

  .narrow {
    padding: 0 7px;
  }
}
.days-filter {
  width: 110px;
}
.font-x-small {
  font-size: 12px;
}
.v-data-table {
  .top-footer-item {
    border-top: none;
  }
  ::v-deep thead th {
    background-color: $nn-TB_T98;
    letter-spacing: -0.02em;
    color: $nn-D_T20 !important;
    height: 40px !important;
    line-height: 12px;
    border-bottom: none !important;
    > span {
      display: inline-flex;
      height: 100%;
      align-items: center;
    }
  }
}

.clear-link {
  color: $nn-sea-blue;
}

.squeze-margin {
  ::v-deep .v-data-footer__pagination {
    margin: 0px 16px !important;
  }

  ::v-deep .v-select {
    margin: 13px 0px 13px 16px !important;
  }
}
</style>
