<template>
  <tr
    :class="[
      {
        'weekly-background': colorWeeklyDose,
        'squeeze-table': squeezeTable,
      },
    ]"
  >
    <td class="pl-6" :class="{ 'is-deleted': item.isDeleted }">
      <InsulinDiaryDoseType
        :doseTypeShort="item.doseTypeShort"
        :isFollowUp="item.isFollowUp"
        :landscape="landscape"
        :isDeleted="item.isDeleted"
      />
    </td>
    <td :class="{ 'is-deleted': item.isDeleted }">
      <DateFormat :value="item.date" data-testid="insulin-dose-date" />
    </td>
    <template v-if="doseTaken">
      <td class="text-right" :class="{ 'is-deleted': item.isDeleted }">
        <InsulinDiaryDoseTime
          :doseTime="item.time"
          :hourFormatString="hourFormatString"
          :landscape="landscape"
          :isDeleted="item.isDeleted"
        />
      </td>
      <td class="text-right" :class="{ 'is-deleted': item.isDeleted }">
        <InsulinDiaryDoseValue
          :dose="item"
          :landscape="landscape"
          :isDeleted="item.isDeleted"
        />
      </td>
      <td
        v-if="!hideCarbsConsumed"
        class="text-right"
        :class="{ 'is-deleted': item.isDeleted }"
      >
        <InsulinDiaryCarbsConsumed
          :carbsConsumed="item.carbsConsumed"
          :carbsConsumedType="item.carbsConsumedType"
          :landscape="landscape"
          :isDeleted="item.isDeleted"
        />
      </td>
    </template>
    <td
      v-else
      :colspan="hideCarbsConsumed ? 2 : 3"
      :class="{ 'is-deleted': item.isDeleted }"
    >
      <v-chip
        label
        small
        :color="labelColor"
        text-color="#333"
        class="width-100-pct align-center justify-center"
        data-testid="bmg-chip-msg"
      >
        <span
          :class="{
            'deleted-dose-color': item.isDeleted,
          }"
        >
          {{ doseLabel }}
        </span>
      </v-chip>
    </td>
    <td class="text-right" v-if="!hidePrescribedDose">
      <InsulinDiaryPrescribedDose :dose="item" />
    </td>
    <td class="text-right pr-6" v-if="!hideAudit">
      <InsulinAuditDialog
        v-if="item.versionCount"
        :patient-no="patientNo"
        :item-id="item.insulinId"
        :version-count="item.versionCount"
        :landscape="landscape"
        :hideCarbsConsumed="item.carbsConsumedType === 'na'"
      />
    </td>
  </tr>
</template>

<script>
import { format24h } from '@/utils/date/hourFormatter'
import InsulinAuditDialog from './_InsulinAuditDialog'
import InsulinDiaryDoseType from './_InsulinDiaryDoseType'
import DateFormat from '../DateFormat'
import InsulinListMixin from './InsulinListMixin'

export default {
  name: 'InsulinDoseRow',
  mixins: [InsulinListMixin],
  components: {
    DateFormat,
    InsulinAuditDialog,
    InsulinDiaryDoseType,
    InsulinDiaryCarbsConsumed: () => import('./_InsulinDiaryCarbsConsumed.vue'),
    InsulinDiaryDoseTime: () => import('./_InsulinDiaryDoseTime.vue'),
    InsulinDiaryDoseValue: () => import('./_InsulinDiaryDoseValue.vue'),
    InsulinDiaryPrescribedDose: () =>
      import('./_InsulinDiaryPrescribedDose.vue'),
  },
  props: {
    hourFormatString: { type: String, required: true, default: format24h },
    patientNo: { type: String, required: true },
    hasMultipleDoseTypes: { type: Boolean, required: false, default: false },
    hideAudit: { type: Boolean, required: false, default: false },
    hideCarbsConsumed: { type: Boolean, required: false, default: false },
    hidePrescribedDose: { type: Boolean, required: false, default: false },
    landscape: { type: Boolean, required: true },
  },
  computed: {
    colorWeeklyDose() {
      return (
        this.hasMultipleDoseTypes && this.item.timepoint === 'basal_once_weekly'
      )
    },
    squeezeTable() {
      return (
        !this.landscape && !this.hideCarbsConsumed && !this.hidePrescribedDose
      )
    },
  },
}
</script>

<style lang="scss" scoped>
.weekly-background {
  background-color: rgba(244, 248, 254, 0.5);
}

.squeeze-table {
  td {
    padding: 0 8px !important;
  }
  td:first-child {
    padding-left: 24px !important;
  }
  td:last-child {
    padding-right: 24px !important;
  }
}
</style>
