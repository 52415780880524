<template>
  <v-chip
    v-if="isFollowUp"
    label
    small
    color="#D4E9E8"
    data-testid="follow-up-dose-label"
  >
    <span
      :class="{
        'deleted-dose-color': isDeleted,
      }"
    >
      {{ doseType }}
    </span>
  </v-chip>
  <span v-else>
    {{ doseType }}
  </span>
</template>

<script>
export default {
  name: 'InsulinDiaryDoseType',
  props: {
    isFollowUp: { type: Boolean, required: true },
    landscape: { type: Boolean, required: true },
    doseTypeShort: { type: String, required: true },
    isDeleted: { type: Boolean, required: false, default: false },
  },
  computed: {
    doseType() {
      return this.landscape ? `${this.doseTypeShort} dose` : this.doseTypeShort
    },
  },
}
</script>
