<template>
  <v-dialog
    v-if="versionCount && versionCount > 0"
    v-model="showDialog"
    :max-width="960"
    data-testid="insulin-audit-dialog"
  >
    <template #activator="{ on }">
      <v-btn
        fab
        x-small
        :color="getAuditDialogBtnColor(versionCount)"
        class="lighten-5 elevation-1"
        v-on="on"
      >
        <v-icon>mdi-history</v-icon>
      </v-btn>
    </template>

    <DialogCard @closeDialog="showDialog = false">
      <template #title> Audit trail for dose value </template>

      <v-data-table
        :disable-sort="true"
        :fixed-header="true"
        :headers="getTableHeaders()"
        :items="tableData"
        item-key="rowVersionId"
        :loading="isLoading"
        :footer-props="{
          'items-per-page-options': itemsPerPageOptions,
        }"
        dense
      >
        <template #no-data>
          <UnableToLoadData v-if="state === loadingState.LOAD_ERRORED" />
        </template>

        <template #loading>
          <v-skeleton-loader
            v-for="i in skeletonCount"
            :key="`skeleton_${i}`"
            height="38"
            type="table-row"
          />
        </template>

        <template #item="{ item, index }">
          <InsulinAuditRow
            :item="item"
            :previousItem="previousItem(index)"
            :hideCarbsConsumed="hideCarbsConsumed"
            :hourFormatString="hourFormatString"
            :landscape="landscape"
            :anyBolusTimepoint="anyBolusTimepoint"
          />
        </template>
      </v-data-table>
    </DialogCard>
  </v-dialog>
</template>

<script>
import Vue from 'vue'
import loadingState from '@/constants/loadingState'
import itemsPerPageOptions from '@/constants/itemsPerPageOptionsPagination'
import service from '@/services/insulin-service'
import { selectedPatientMapGetters } from '@/store/modules/selectedPatientModule'
import hourFormatter from '@/utils/date/hourFormatter'
import DialogCard from '@/components/DialogCard.vue'
import auditDialogMixin from '@/components/mixins/auditDialogMixin'

export default Vue.extend({
  name: 'InsulinAuditDialog',
  components: {
    InsulinAuditRow: () => import('./_InsulinAuditRow.vue'),
    UnableToLoadData: () => import('@/components/UnableToLoadData.vue'),
    DialogCard,
  },
  mixins: [auditDialogMixin],
  props: {
    itemId: { type: Number, required: true },
    versionCount: { type: Number, required: true },
    patientNo: { type: String, required: true },
    hideCarbsConsumed: { type: Boolean, required: false, default: false },
    landscape: { type: Boolean, required: true },
  },
  data() {
    return {
      itemsPerPageOptions,
      state: loadingState.INITIAL,
      loadingState,
      data: null,
      showDialog: false,
    }
  },
  watch: {
    showDialog(newShowDialog) {
      if (newShowDialog) {
        this.loadData()
      }
    },
  },
  computed: {
    ...selectedPatientMapGetters(),
    tableData() {
      return this.data?.items || []
    },
    clockNotation() {
      return this.selectedPatientClockNotation(this.patientNo)
    },
    clockNotationDisplay() {
      return `(${this.clockNotation})`
    },
    hourFormatString() {
      return hourFormatter(this.clockNotation)
    },
    isLoading() {
      return this.state === loadingState.LOADING
    },
    skeletonCount() {
      return this.versionCount > 10 ? 10 : this.versionCount
    },
    anyBolusTimepoint() {
      return this.tableData.some(d => d.timepoint.startsWith('bolus_'))
    },
  },
  methods: {
    getTableHeaders() {
      let list = [
        { text: 'Date', value: 'date', sortable: false },
        {
          text: `Time ${this.clockNotationDisplay}`,
          value: 'time',
          sortable: false,
          align: 'left',
        },
        {
          text: `Mealtime`,
          value: 'mealtime',
          sortable: false,
          align: 'left',
        },
        {
          text: 'Dose taken',
          value: 'actualDose',
          sortable: false,
          align: 'right',
        },
        {
          text: 'Carbs consumed',
          value: 'carbs',
          sortable: false,
          align: 'right',
        },
        { text: 'Modified', value: 'versionAt' },
        { text: 'By / reason', value: 'byReason' },
      ]

      if (this.hideCarbsConsumed) {
        list = list.filter(item => item.value !== 'carbs')
      }

      if (!this.anyBolusTimepoint) {
        list = list.filter(item => item.value !== 'mealtime')
      }

      return list
    },
    loadData() {
      this.state = loadingState.LOADING
      service
        .getInsulinHistory(this.patientNo, this.itemId)
        .then(response => {
          this.state = loadingState.LOAD_SUCCEEDED
          this.data = response
        })
        .catch(error => {
          this.state = loadingState.LOAD_ERRORED
          this.$log.error(error)
          Vue.$tracking.componentError(this, error)
        })
    },
    previousItem(index) {
      // The latest element is the first in the list, to go back in history the index has to be increased
      const previousVersionIndex = index + 1

      if (previousVersionIndex <= this.tableData.length) {
        return this.tableData[previousVersionIndex]
      }

      return null
    },
  },
})
</script>
