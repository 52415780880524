export default {
  props: {
    item: { type: Object, required: true },
  },
  computed: {
    doseTaken() {
      return this.item.type === 'doseTaken'
    },
    labelColor() {
      if (this.item.type === 'dataPending') return '#f5f6f9'
      return '#FDF7E6'
    },
    doseLabel() {
      if (this.item.type === 'dataPending') return 'Pending'
      if (this.item.type === 'noDataAvailable') return 'No data'
      if (this.item.type === 'missedDose') return 'Missed'

      return '?'
    },
  },
}
