import Vue from 'vue'
import dateTimeWithTimeZone from '@/utils/date/dateTimeWithTimeZone'

export default {
  getInsulinList(patientNumber, days) {
    return new Promise((resolve, reject) => {
      const currentClientTime = dateTimeWithTimeZone()
      Vue.$http
        .get('/web/patient/insulin/list', {
          params: {
            patientNumber,
            days,
            currentClientTime,
          },
        })
        .then(response => resolve(response.data))
        .catch(error => {
          Vue.$log.error(error)
          Vue.$tracking.error('getInsulinList error', error, {
            patientNumber,
            days,
            currentClientTime,
          })
          reject(error)
        })
    })
  },

  getInsulinHistory(patientNumber, insulinId) {
    return new Promise((resolve, reject) => {
      Vue.$http
        .get('/web/patient/insulin/history', {
          params: {
            patientNumber,
            insulinId,
          },
        })
        .then(response => resolve(response.data))
        .catch(error => {
          Vue.$log.error(error)
          Vue.$tracking.error('getInsulinHistory error', error, {
            patientNumber,
            insulinId,
          })
          reject(error)
        })
    })
  },
}
